<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRadar } from '~/store/radar'
import { useLocaleThemePath } from '~/composables/locale'
import { LordIconSource, LordIconTrigger } from '~/types/assets'
import type { LordIcon } from '~/types/assets'
import { useRoot } from '~/store'

const emits = defineEmits<{ (e: 'onNavigation'): void }>()

const pawn: LordIcon = {
  src: LordIconSource.greekHelmet,
  trigger: LordIconTrigger.loop,
  revert: true,
  delay: 500,
  size: 65
}

const radar = useRadar()

const { theme } = storeToRefs(useRoot())

const onNavigation = (i: number) => {
  radar.onNavigation(i)
  emits('onNavigation')
}
</script>

<template lang="pug">
nav.radar__outer
  .radar__inner
    .radar__ray
      .radar__ray__wake(
        :style="`filter: blur(30px) drop-shadow(20px 20px 20px ${theme?.hexSecondary})`"
      )
    .radar__circle
    .radar__dial(
      v-for="(route, index) in radar.routes"
      :key="`route-${index}`"
      v-tooltip="$t(`routes.${route.name}.description`)"
      @click="onNavigation(index)"
    )
      .radar__dial__pseudo
      NuxtLink(
        :to="useLocaleThemePath(route?.path)"
        :title="`${$t('layout.radar.anchors.title')} ${$t(`routes.${route.name}.name`)}`"
        :aria-label="`${$t('layout.radar.anchors.aria-label')} ${$t(`routes.${route.name}.name`)}`"
      )
        Icon(:name="route.icon?.src")
        span {{ $t(`routes.${route.name}.name`) }}
    .radar__pawn__outer(
      :style="{ left: `calc(5rem * ${radar.pawnOffsetX})`, top: `calc(5rem * ${radar.pawnOffsetY})` }"
    )
      .radar__pawn__inner
        IconLord(v-bind="pawn")
</template>

<style lang="sass" scoped>
$radar-dial: 5rem
$row-length: 3

nav.radar__outer
  @apply bg-light-400 dark:bg-dark-900 border-2 border-dark-800 overflow-hidden relative
  .radar__inner
    @apply flex flex-wrap
    width: calc($radar-dial * $row-length)
    height: calc($radar-dial * $row-length)
    .radar__dial
      @apply relative cursor-pointer transition-all border border-light-100 dark:border-dark-900 hover:p-0.5 z-30
      width: $radar-dial
      height: $radar-dial
      a
        @apply w-full h-full flex flex-col items-center justify-center text-white dark:text-dark-400 uppercase text-sm transition-all
        [class*="i-"]
          @apply text-2xl bg-dark-400 transition-all
      &:hover a
        @apply text-white
        [class*="i-"]
          @apply bg-white
      // &:hover
      //   a
      //     @apply text-secondary dark:text-secondary
      //     [class*="i-"]
      //       @apply text-secondary dark:text-secondary
      &::before, &::after, .radar__dial__pseudo::after, .radar__dial__pseudo::before
        content: ''
        @apply absolute w-4 h-4 border-primary z-20 transition-all
      &::before
        @apply right-1 top-1 border-r border-t
      &::after
        @apply left-1 top-1 border-l border-t
      .radar__dial__pseudo
        &::before
          @apply right-1 bottom-1 border-r border-b
        &::after
          @apply left-1 bottom-1 border-l border-b
      &:hover
        &::before, &::after, .radar__dial__pseudo::after, .radar__dial__pseudo::before
          @apply w-2 h-2
    .radar__pawn__outer
      @apply absolute z-40 flex items-center justify-center
      width: $radar-dial
      height: $radar-dial
      transition: all 1s
      .radar__pawn__inner
        @apply rounded-[50%] bg-light-500 dark:bg-dark-800 border border-white dark:border-dark-500 flex-shrink-0
    .radar__circle
      &::before, &::after
        content: ''
        @apply absolute rounded-[50%] border-2 border-secondary z-10
      &::before
        @apply top-4 left-4 right-4 bottom-4 animate-radar-circle-fast
      &::after
        @apply top-16 left-16 right-16 bottom-16 animate-radar-circle-slow
    .radar__ray
      @apply absolute top-[50%] left-[50%] w-full h-full bg-transparent border-t border-dashed border-primary animate-spin-slow
      transform-origin: top left
      .radar__ray__wake
        @apply absolute top-0 left-0 w-full h-full bg-secondary rotate-[-55deg] opacity-30
        transform-origin: top left
      // top: calc($radar-dial * $row-length * -1)
      // bottom: calc($radar-dial * $row-length * -1)
      // @apply absolute left-[50%] translate-x-[-50%] w-1 animate-spin-slow opacity-50 z-10 bg-light-200 dark:bg-dark-700
</style>
